import { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'

export function useViewport() {
  const theme = useTheme()

  const [viewport, getDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const setDimension = () => {
    getDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension)

    return () => {
      window.removeEventListener('resize', setDimension)
    }
  }, [viewport])

  const isMobile = viewport.width < theme.breakpoints.values.md

  return { isMobile, viewport }
}
