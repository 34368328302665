import { Grid, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    backgroundColor: '#F3F3F3',
  },
})

export function Loading() {
  const classes = useStyles()
  document.title = 'Carregando...'

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <CircularProgress size={80} />
    </Grid>
  )
}
