import { Root as LocationRoot } from './Root'
import { Title as LocationTitle } from './Title'
import { Text as LocationText } from './Text'
import { Icon as LocationIcon } from './Icon'

export const Location = {
  Root: LocationRoot,
  Title: LocationTitle,
  Text: LocationText,
  Icon: LocationIcon,
}
