import PhoneIcon from '@mui/icons-material/Phone'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded'

import { handleMapsRoute, handlePhoneNumber, handleWhatsApp } from '~/utils/open-external-link'
import { maskFormatter } from '~/utils/mask'
import { StoreType } from '~/schemas/store'

export function mountOptions(options: StoreType) {
  const menuOptions = [
    options?.whatsapp
      ? {
          id: 'whatsapp',
          icon: <WhatsAppIcon color="primary" style={{ marginRight: 8 }} />,
          label: maskFormatter(options?.whatsapp),
          handler: () => handleWhatsApp(options?.whatsapp),
        }
      : undefined,
    options?.phoneNumber
      ? {
          id: 'phone',
          icon: <PhoneIcon color="primary" style={{ marginRight: 8 }} />,
          label: maskFormatter(options?.phoneNumber),
          handler: () => handlePhoneNumber(options?.phoneNumber),
        }
      : undefined,
    {
      id: 'route',
      icon: <DirectionsRoundedIcon color="primary" style={{ marginRight: 8 }} />,
      label: 'Ver rota para loja',
      handler: () =>
        handleMapsRoute(
          options.placeCoordinates?.formattedAddress || options.coordinates?.formattedAddress,
        ),
    },
  ]

  return menuOptions
}
