import { Container, ImageList, ImageListItem, Typography, Grid } from '@mui/material'

import { useViewport } from '~/hooks/useViewport'
import ImageNotSupported from '~/assets/imageNotSupported.svg'

type Image = {
  mediaFormat: string
  locationAssociation: {
    category: string
  }
  googleUrl: string
  thumbnailUrl: string
}

type GalleryImageProps = {
  background?: string
  images: Array<Image>
}

export function Gallery({ background = 'none', images }: GalleryImageProps) {
  const { isMobile } = useViewport()

  return (
    <Container
      id="gallery"
      maxWidth="xl"
      component="section"
      style={{ backgroundColor: background, padding: '32px 0' }}
    >
      <Grid container direction="column" alignItems="center">
        <Typography variant="h2" style={{ fontWeight: 'bold', fontSize: 24 }}>
          Galeria de fotos
        </Typography>
        <ImageList
          sx={{ width: 'fit-content' }}
          cols={isMobile ? images.length : 3}
          style={{ maxWidth: '100%', padding: 8 }}
        >
          {images?.map((image: Image, index: number) => (
            <ImageListItem key={index} style={{ padding: 8 }}>
              <img
                style={{
                  width: 250,
                  height: 250,
                  border: '2px solid #F2F2F2',
                  borderRadius: 4,
                  objectFit: 'cover',
                }}
                src={image.thumbnailUrl}
                alt={image.mediaFormat}
                referrerPolicy="no-referrer"
                loading="lazy"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = ImageNotSupported
                  currentTarget.style.objectFit = 'contain'
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
    </Container>
  )
}
