import { ReactNode, useMemo } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useApp } from './hooks/useApp'

type ConfigThemeType = {
  colorPrimary?: string
  colorSecondary?: string
  fontFamily?: string
}

type ThemeType = {
  children: ReactNode
}

const createMuiTheme = (cfg: ConfigThemeType | undefined) => {
  const customTheme = createTheme({
    ...(cfg?.fontFamily === 'Gotham' && {
      components: {
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
              font-family: 'Gotham';
              font-style: normal;
              font-weight: normal;
              src: local('Gotham Medium'), url('/fonts/GothamMedium.ttf') format('truetype');
            }
            @font-face {
              font-family: 'Gotham';
              font-style: normal;
              font-weight: 700;
              src: local('Gotham Bold'), url('/fonts/GothamBold.ttf') format('truetype');
            }
            @font-face {
              font-family: 'Gotham';
              font-style: normal;
              font-weight: 900;
              src: local('Gotham Black'), url('/fonts/GothamBlack.ttf') format('truetype');
            }
        `,
        },
      },
      typography: {
        fontFamily: 'Gotham, Roboto, sans-serif',
      },
    }),
    palette:
      cfg?.colorPrimary && cfg?.colorSecondary
        ? {
            primary: {
              main: cfg?.colorPrimary,
            },
            secondary: {
              main: cfg?.colorSecondary,
            },
          }
        : {
            primary: {
              main: '#0052cc',
            },
            secondary: {
              main: '#edf2ff',
            },
          },
  })

  return customTheme
}

export function Theme({ children }: ThemeType) {
  const { config } = useApp()

  const theme = useMemo(() => {
    const result = createMuiTheme(config as ConfigThemeType)
    return result
  }, [config])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
