/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'

import { makeStyles } from '@mui/styles'
import { Grid, Typography, Paper, Button } from '@mui/material'

import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import YouTubeIcon from '@mui/icons-material/YouTube'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded'
import { RoomOutlined } from '@mui/icons-material'

import { ButtonFloatingWhatsApp } from '~/components/Buttons/ButtonFloatingWhatsApp'
import { ButtonWhatsApp } from '~/components/Buttons/ButtonWhatsApp'
import { ButtonRoute } from '~/components/Buttons/ButtonRoute'
import { ButtonPhone } from '~/components/Buttons/ButtonPhone'
import { DialogTime } from '~/components/DialogTime'
import { Divider } from '~/components/Divider'
import { Loading } from '~/components/Loading'
import { Location } from '~/components/Location'
import { Menu } from '~/components/Menu'
import { TextEllipsis } from '~/components/TextEllipsis'
import { Footer } from '~/components/Footer'

import { useApp } from '~/hooks/useApp'
import { useFetch } from '~/hooks/useFetch'
import { useViewport } from '~/hooks/useViewport'

import { ComponentProps, GMBType } from '~/types/app'
import { API, GMB } from '~/services/api'

import { maskFormatter } from '~/utils/mask'
import { refinePeriod, transformPeriod } from '~/utils/google-parse-period'

import { productCars, productMotorcycles } from '~/helpers/vehicle'
import { mountOptions } from '~/pages/helpers/mountOptions'
import { StoreSchema, StoreType } from '~/schemas/store'

const URL_IMAGE_REPO = `https://images.pertinhodemim.com/retailLandingPage`

const useStyles = makeStyles({
  header: {
    flex: 1,
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      '&:first-child span': {
        fontWeight: 500,
        textTransform: 'uppercase',
        textAlign: 'center',
      },
    },
  },
  storeLogo: {
    width: 100,
    height: 50,
    margin: '0 8px',
  },
  groupButtons: {
    display: 'flex',
    gap: 8,
  },
  banner: {
    height: '100%',
    gap: 8,
    '& img': {
      width: '100%',
      height: '100%',
      maxHeight: 485,
      objectFit: 'cover',
    },
  },
  paper: {
    width: 300,
    height: 308,
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    gap: 8,
  },
  cardInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 32,
    gap: 16,
  },
  cardProduct: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 8,
  },
  footer: {
    width: '100%',
    padding: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 64,
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 8,
      '& > p': { fontWeight: 700 },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
      },
    },
  },
})

interface VehiclePageProps {
  vehicle?: 'car' | 'motorcycle'
}

function HeaderPage({ store, isMobile }: Omit<ComponentProps, 'gmb'>) {
  const classes = useStyles()

  const { logo, whatsapp = '', phoneNumber = '' } = store

  const menuOptions = mountOptions(store)

  return (
    <Grid component="header" className={classes.header}>
      <Grid>
        {logo ? (
          <img src={logo} alt="Logo" loading="lazy" className={classes.storeLogo} />
        ) : (
          <img src={`${URL_IMAGE_REPO}/logo_bateria.png`} alt="Logo" loading="lazy" />
        )}

        <Typography component="span" color="primary">
          {store?.placeName || store?.name}
        </Typography>
      </Grid>

      {isMobile ? (
        <Menu
          menuIcon={<MoreVertIcon color="primary" />}
          menuHorizontal="right"
          menuOptions={menuOptions}
        />
      ) : (
        <Grid className={classes.groupButtons}>
          {whatsapp && (
            <>
              <ButtonWhatsApp whatsapp={whatsapp} backgroundColor="#FFF200" />
              <Typography variant="body1" align="center" noWrap>
                ou ligue:
                <strong>{maskFormatter(phoneNumber)}</strong>
              </Typography>
            </>
          )}

          {!whatsapp && phoneNumber && (
            <ButtonPhone textSuffix="Telefone:" phone={store?.phoneNumber} />
          )}

          <ButtonRoute
            address={
              store?.placeCoordinates?.formattedAddress || store?.coordinates?.formattedAddress
            }
            icon={<DirectionsRoundedIcon color="primary" style={{ fontSize: 32 }} />}
          />
        </Grid>
      )}
    </Grid>
  )
}

function FooterPage({ isMobile }: Omit<ComponentProps, 'store' | 'gmb'>) {
  const classes = useStyles()

  return (
    <Grid
      component="footer"
      className={classes.footer}
      sx={{ flexDirection: isMobile ? 'column' : 'row' }}
    >
      <img src="https://images.pertinhodemim.com/retailLandingPage/grupo-heliar.png" alt="Heliar" />
      <Grid>
        <Typography variant="body1" color="secondary">
          Siga-nos:
        </Typography>
        <Grid style={{ flexDirection: isMobile ? 'column' : 'row' }}>
          <Button
            component="a"
            target="_blank"
            href="https://www.facebook.com/bateriasheliar"
            startIcon={<FacebookIcon color="primary" />}
          >
            <Typography color="primary" variant="body1" style={{ textTransform: 'capitalize' }}>
              facebook
            </Typography>
          </Button>
          <Button
            component="a"
            target="_blank"
            href="https://www.instagram.com/bateriasheliar"
            startIcon={<InstagramIcon color="primary" />}
          >
            <Typography color="primary" variant="body1" style={{ textTransform: 'capitalize' }}>
              Instagram
            </Typography>
          </Button>
          <Button
            component="a"
            target="_blank"
            href="https://www.youtube.com/user/BateriasHeliar"
            startIcon={<YouTubeIcon color="primary" />}
          >
            <Typography color="primary" variant="body1" style={{ textTransform: 'capitalize' }}>
              Youtube
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export function VehiclePage({ vehicle = 'car' }: VehiclePageProps) {
  const classes = useStyles()

  const { cnpj } = useParams<{ cnpj: string }>()
  const { isMobile } = useViewport()
  const { setConfig } = useApp()

  const { data: store, fetching: isFetchingStore } = useFetch<StoreType>(`${API}/api`, cnpj)
  const { data: gmb, fetching: isFetchingGMB } = useFetch<GMBType | null>(
    `${GMB}/landingPage-location`,
    cnpj,
    {
      headers: { 'x-api-key': 'g5J>[Vchx4$&t`5~' },
    },
  )

  const storeData = (store && StoreSchema.parse(store)) || null
  const periodData =
    gmb?.data?.regularHours?.periods && Array.isArray(gmb?.data?.regularHours?.periods)
      ? gmb?.data?.regularHours?.periods
      : null

  const storePeriod = transformPeriod(periodData || [])
  const refineStorePeriod = storePeriod ? refinePeriod(storePeriod) : null

  const currentDay = new Date().getDay()
  const currentPeriod = refineStorePeriod ? refineStorePeriod[currentDay] : null

  useEffect(() => {
    setConfig({ colorPrimary: '#006437', colorSecondary: '#006437' })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isFetchingStore || isFetchingGMB) return <Loading />

  if (!store || !storeData) return null

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          href="https://images.pertinhodemim.com/retailLandingPage/logo_bateria.png"
        />
        <meta name="description" content="Compre aqui suas baterias Heliar" />
        <title>{`Loja | ${storeData.placeName || storeData.name}`}</title>
        <link rel="canonical" href={window.location.href} />

        {/* Open Graph */}
        <meta property="og:title" content="Baterias Heliar" />
        <meta property="og:description" content="Compre aqui suas baterias Heliar" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://images.pertinhodemim.com/retailLandingPage/carros-heliar-mobile.png"
        />

        {/* Google Tag Manager - Client */}
        <script>
          {`
            ;(function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-P78SQM3');
          `}
        </script>
        {/* End · Google Tag Manager - Client */}

        {/* Google Tag Manager - GOFIND */}
        <script>
          {`
            (function(w, d, s, l, i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l!= 'dataLayer' ? '&l='+l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-NDV6BFS');
          `}
        </script>
        {/* End · Google Tag Manager - GOFIND */}

        {/* Global site tag (gtag.js) - Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-69739739-20"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date()); gtag('config', 'UA-69739739-20');
          `}
        </script>
        {/* End · Global site tag (gtag.js) - Google Analytics */}
      </Helmet>

      <div style={{ width: '100%', maxWidth: 1380, margin: 'auto' }}>
        <HeaderPage store={storeData} isMobile={isMobile} />

        <Grid container className={classes.banner}>
          <img
            src={
              isMobile
                ? vehicle === 'car'
                  ? `${URL_IMAGE_REPO}/carros-heliar-mobile.png`
                  : `${URL_IMAGE_REPO}/motos-mobile.png`
                : vehicle === 'car'
                ? `${URL_IMAGE_REPO}/banner.png`
                : `${URL_IMAGE_REPO}/heliarmotos-banner.png`
            }
            alt="Banner"
            loading="lazy"
          />
        </Grid>

        <Divider />

        <Grid
          container
          id="products"
          component="main"
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            padding: '40px 16px',
            background: 'linear-gradient(to bottom, #EFEFEF 0%, #FFF 100%)',
          }}
        >
          <Grid item className={classes.cardInfo}>
            {!!periodData && (
              <Paper className={classes.paper}>
                <Grid>
                  <img src={`${URL_IMAGE_REPO}/calendar.png`} alt="calendário" />

                  <Typography variant="h2" color="primary" style={{ fontSize: 24 }}>
                    <strong>Horários</strong> de <br /> atendimento
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="body1">
                    Hoje{' '}
                    {currentPeriod?.isOpen ? `aberto até ás ${currentPeriod.close}` : 'fechado'}
                  </Typography>
                  <DialogTime periods={refineStorePeriod} />
                </Grid>
              </Paper>
            )}

            <Paper className={classes.paper}>
              <Grid>
                <img src={`${URL_IMAGE_REPO}/pay.png`} alt="pagamento" />

                <Typography variant="h2" color="primary" style={{ fontSize: 24 }}>
                  Compre sua <br /> bateria
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body1" color="primary">
                  Pagamento <br /> <strong>parcelado.</strong>
                </Typography>
              </Grid>
              <Grid />
            </Paper>

            {vehicle !== 'motorcycle' && (
              <Paper className={classes.paper}>
                <Grid>
                  <img src={`${URL_IMAGE_REPO}/delivery.png`} alt="delivery" />

                  <Typography variant="h2" color="primary" style={{ fontSize: 24 }}>
                    Entrega <br /> <strong>Rápida</strong>
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="body1" color="primary">
                    Entre em contato conosco para receber sua bateria no menor tempo possível.
                  </Typography>
                  <Typography variant="body1" color="primary" style={{ fontSize: 12 }}>
                    *Consulte a área de cobertura para essa <br /> entrega
                  </Typography>
                </Grid>
              </Paper>
            )}

            <Paper className={classes.paper}>
              <Grid>
                <img src={`${URL_IMAGE_REPO}/certified.png`} alt="certificado" />
                <Typography variant="h1" color="primary" style={{ fontSize: 24 }}>
                  Revendedor <br /> oficial <strong>Heliar</strong>
                </Typography>
              </Grid>
              <Grid>
                <TextEllipsis lineClamp={6} color="primary">
                  Aproveite a qualidade de atendimento e toda a linha de baterias Heliar para
                  rapidamente resolver seu problema, sem dor de cabeça!
                </TextEllipsis>
              </Grid>
            </Paper>
          </Grid>

          <Typography variant="h5" color="primary" style={{ fontWeight: 700, margin: '32px 0' }}>
            Baterias Heliar {vehicle === 'motorcycle' ? ' - Motos' : ' - Carros'}
          </Typography>

          <Grid item className={classes.cardProduct}>
            {vehicle === 'car' ? (
              <>
                {productCars.map(({ name, image, description }, index) => (
                  <Grid key={index} item style={{ width: 250, textAlign: 'center' }}>
                    <img src={`${URL_IMAGE_REPO}/${image}`} alt={name} width="220" />

                    <Typography variant="body1" color="primary" style={{ marginTop: 8 }}>
                      {description}
                    </Typography>
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {productMotorcycles.map(({ name, title, image, description }, index) => (
                  <Grid key={index} item style={{ width: 250, textAlign: 'center' }}>
                    <img src={`${URL_IMAGE_REPO}/${image}`} alt={name} width="220" />

                    <Typography variant="h6" color="primary">
                      {title}
                    </Typography>

                    <Typography variant="body1" color="primary" style={{ marginTop: 8 }}>
                      {description}
                    </Typography>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Grid>

        <Divider />

        {storeData.placeCoordinates || storeData.coordinates ? (
          <Location.Root
            name={storeData.placeName || storeData.name}
            coordinates={storeData.placeCoordinates || storeData.coordinates}
            embedGoogle={storeData.gmbMapSrc}
            style={{ marginTop: 40, padding: 16 }}
          >
            <Location.Title
              sx={{
                fontSize: '24px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Location.Text>Onde estamos</Location.Text>
            </Location.Title>

            <Typography variant="body1" align="center" color="primary" sx={{ marginBlock: '16px' }}>
              {storeData.placeCoordinates?.formattedAddress ||
                storeData.coordinates?.formattedAddress}
            </Typography>

            <ButtonRoute
              address={
                storeData.placeCoordinates?.formattedAddress ||
                storeData.coordinates?.formattedAddress
              }
              icon={<RoomOutlined color="secondary" style={{ fontSize: 24 }} />}
            />
          </Location.Root>
        ) : null}

        <FooterPage isMobile={isMobile} />
        <Footer />
      </div>

      {storeData.whatsapp && <ButtonFloatingWhatsApp whatsapp={storeData.whatsapp} />}
    </>
  )
}
