import { Button, Typography } from '@mui/material'

import { maskFormatter } from '~/utils/mask'

type ButtonPhoneProps = {
  textSuffix?: string
  text?: string
  phone: string
  icon?: React.ReactNode
  btnOrder?: number
}

export function ButtonPhone({
  textSuffix = '',
  text = '',
  phone,
  icon,
  btnOrder,
}: ButtonPhoneProps) {
  return (
    <Button
      component="a"
      href={`tel:${phone}`}
      startIcon={icon}
      style={{
        order: btnOrder,
        textTransform: 'initial',
        textDecoration: 'underline',

        color: '#000',
      }}
    >
      <Typography variant="body1" color="secondary" noWrap width="content">
        {textSuffix} {text || <strong>{maskFormatter(phone)}</strong>}
      </Typography>
    </Button>
  )
}
