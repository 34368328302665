import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { makeStyles } from '@mui/styles'
import { Avatar, Button, Grid, Paper, Typography } from '@mui/material'

import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'

import { Loading } from '~/components/Loading'
import { DialogTime } from '~/components/DialogTime'
import { ButtonRoute } from '~/components/Buttons/ButtonRoute'
import { ButtonPhone } from '~/components/Buttons/ButtonPhone'
import { Location } from '~/components/Location'
import { Footer } from '~/components/Footer'

import { useApp } from '~/hooks/useApp'
import { useFetch } from '~/hooks/useFetch'
import { useViewport } from '~/hooks/useViewport'

import { API, GMB } from '~/services/api'
import { GMBType } from '~/types/app'

import { maskFormatter } from '~/utils/mask'
import { StoreSchema, StoreType } from '~/schemas/store'
import { refinePeriod, transformPeriod } from '~/utils/google-parse-period'

const URL_IMAGE_REPO = 'https://images.pertinhodemim.com/retailLandingPage'
const URL_IMAGE_REPO_S3 = 'https://s3.sa-east-1.amazonaws.com/gofind.img.repo/retailLandingPage'

const useStyles = makeStyles((theme: any) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    width: '100%',
    maxWidth: 1136,
    margin: '0 auto',
    padding: '24px 16px',
  },
  figure: {
    position: 'relative',
    margin: 0,
    height: '100%',
    '& img': {
      width: '100%',
      height: '100%',
      maxHeight: 485,
      objectFit: 'cover',
    },
  },
  figCaption: {
    position: 'absolute',
    left: 24,
    top: '50%',
    transform: 'translate(0, -50%)',
    fontSize: 32,
    color: '#FFF',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
  },
  paper: {
    width: 300,
    height: 308,
    padding: '24px 16px',
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div h3': {
      fontSize: 24,
      fontWeight: 400,
      marginTop: 24,
      textAlign: 'center',
    },
  },
  avatar: {
    margin: '0 auto',
    padding: 40,

    '& .MuiAvatar-img': {
      width: 'unset',
      height: 'unset',
    },
  },
  cardInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 24,
  },
  contacts: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  infoExtra: {
    display: 'flex',
    '& h3': {
      gap: 16,
      fontSize: 24,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
    },
    '& > p': {
      marginTop: 8,
      fontSize: 24,
    },
  },
  contactsMobile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& h3': {
      gap: 16,
      fontSize: 24,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
    },
    '& > p': {
      marginTop: 8,
      fontSize: 24,
    },
  },
  footer: {
    backgroundColor: '#272F7C',
    width: '100%',
    padding: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const styledButton = {
  gap: '16px',
  display: 'flex',
  backgroundColor: '#FEA67C',
  padding: '8px 16px',
  color: '#FFF',
  borderRadius: 9999,
  '&:hover': { backgroundColor: '#FEA67C' },
  '& > svg': { fontSize: '32px' },
  '& > p': { fontWeight: 500, textAlign: 'center', textTransform: 'initial' },
}

export function SooperPage() {
  const classes = useStyles()

  const { isMobile } = useViewport()
  const { cnpj } = useParams<{ cnpj: string }>()
  const { setConfig } = useApp()

  const { data: store, fetching: isFetchingStore } = useFetch<StoreType>(`${API}/api`, cnpj)
  const { data: gmb, fetching: isFetchingGMB } = useFetch<GMBType | null>(
    `${GMB}/landingPage-location`,
    cnpj,
    {
      headers: { 'x-api-key': 'g5J>[Vchx4$&t`5~' },
    },
  )

  const storeData = (store && StoreSchema.parse(store)) || null
  const periodData =
    gmb?.data?.regularHours?.periods && Array.isArray(gmb?.data?.regularHours?.periods)
      ? gmb?.data?.regularHours?.periods
      : null

  const storePeriod = transformPeriod(periodData || [])
  const refineStorePeriod = storePeriod ? refinePeriod(storePeriod) : null

  const currentDay = new Date().getDay()
  const currentPeriod = refineStorePeriod ? refineStorePeriod[currentDay] : null

  useEffect(() => {
    setConfig({ colorPrimary: '#272F7C', colorSecondary: '#444' })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isFetchingStore || isFetchingGMB) return <Loading />

  if (!store || !storeData) return null

  return (
    <>
      <Helmet>
        <link rel="icon" href={`${URL_IMAGE_REPO_S3}/sooper/logo-group.svg`} />
        <title>{`Loja | ${storeData.placeName || storeData.name}`}</title>
        <link rel="canonical" href={window.location.href} />

        {/* Google Tag Manager - GOFIND */}
        <script>
          {`
            (function(w, d, s, l, i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l!= 'dataLayer' ? '&l='+l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-NDV6BFS');
          `}
        </script>
        {/* End · Google Tag Manager - GOFIND */}
      </Helmet>

      <Grid style={{ width: '100%', maxWidth: 1380, margin: 'auto' }}>
        <Grid component="header" className={classes.header}>
          <img src={`${URL_IMAGE_REPO_S3}/sooper/sooper-logo-blue.svg`} alt="Logo Sooper Grupo" />

          <Grid sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            {storeData.whatsapp ? (
              <Button
                component="a"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=${storeData?.whatsapp}`}
                sx={{ ...styledButton }}
              >
                <WhatsAppIcon />
                <Typography>Peça pelo WhatsApp</Typography>
              </Button>
            ) : null}

            {!isMobile ? (
              <ButtonRoute
                address={
                  storeData.placeCoordinates?.formattedAddress ||
                  storeData.coordinates?.formattedAddress
                }
                icon={<RoomOutlinedIcon style={{ fontSize: 24, color: '#FEA67C' }} />}
              />
            ) : null}
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            paddingBottom: '48px',
            position: 'relative',
            background: 'linear-gradient(to bottom, #FFF,#EFEFEF)',
          }}
        >
          <figure id="banner" className={classes.figure}>
            <img src={`${URL_IMAGE_REPO}/sooper/imagem-topo.png`} alt="Banner Sooper" />
            <figcaption className={classes.figCaption}>
              {storeData.placeName || storeData.name}
            </figcaption>
          </figure>

          <Grid
            item
            id="operations"
            className={classes.cardInfo}
            sx={{ marginTop: isMobile ? '16px' : '-48px !important' }}
          >
            {(storeData.whatsapp || storeData.phoneNumber) && (
              <Paper className={classes.paper}>
                <Grid>
                  <Avatar
                    src={`${URL_IMAGE_REPO_S3}/sooper/Grupo-37.svg`}
                    alt="orçamento"
                    className={classes.avatar}
                    sx={{ width: 56, height: 56, background: '#e3e3e3', zIndex: 9 }}
                  />
                  <Typography variant="h3" color="primary">
                    Solicite um orçamento para garantir os melhores preços.
                  </Typography>
                </Grid>

                {storeData.whatsapp ? (
                  <Button
                    component="a"
                    target="_blank"
                    href={`https://api.whatsapp.com/send?phone=${storeData.whatsapp}`}
                    sx={{ ...styledButton }}
                  >
                    <WhatsAppIcon />
                    <Typography>Peça pelo WhatsApp</Typography>
                  </Button>
                ) : (
                  <ButtonPhone
                    icon={<LocalPhoneOutlinedIcon style={{ fontSize: 28, color: '#FEA67C' }} />}
                    textSuffix="Tel:"
                    phone={storeData.phoneNumber}
                  />
                )}
              </Paper>
            )}

            {!!periodData && (
              <Paper className={classes.paper}>
                <Grid>
                  <Avatar
                    src={`${URL_IMAGE_REPO_S3}/sooper/Grupo-35.svg`}
                    alt="período"
                    className={classes.avatar}
                    sx={{ width: 56, height: 56, background: '#e3e3e3', zIndex: 9 }}
                  />
                  <Typography variant="h3" color="primary">
                    {currentPeriod?.isOpen ? (
                      <>
                        Aberto até <br /> às {currentPeriod.close}
                      </>
                    ) : (
                      'Fechado'
                    )}
                  </Typography>
                </Grid>
                <DialogTime iconColor="#FEA67C" periods={refineStorePeriod} />
              </Paper>
            )}
          </Grid>
        </Grid>

        <Grid
          id="informations"
          component="section"
          className={classes.contacts}
          sx={{
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
          }}
        >
          <Grid id="contacts" sx={{ maxWidth: !isMobile ? '500px' : '100%', padding: '8px 24px' }}>
            <Grid
              className={classes.infoExtra}
              sx={{
                width: '100%',
                flexDirection: 'column',
                alignItems: isMobile ? 'center' : 'flex-start',
              }}
            >
              <Typography
                variant="h3"
                color="primary"
                sx={{ alignSelf: isMobile ? 'center' : 'flex-start' }}
              >
                <RoomOutlinedIcon style={{ fontSize: 28 }} />
                Endereço
              </Typography>
              <Typography variant="body1" sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                {storeData.placeCoordinates?.formattedAddress ||
                  storeData.coordinates?.formattedAddress}
              </Typography>

              {!isMobile ? (
                <Button
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`http://maps.google.com/?q=${
                    storeData.placeCoordinates?.formattedAddress ||
                    storeData.coordinates?.formattedAddress
                  }`}
                  sx={{ ...styledButton, width: 250, marginTop: '16px' }}
                >
                  <RoomOutlinedIcon />
                  <Typography variant="body1">Ver Rota para a loja</Typography>
                </Button>
              ) : null}

              <br />

              {!isMobile ? (
                <>
                  <Typography variant="h3" color="primary">
                    <LocalPhoneOutlinedIcon style={{ fontSize: 28 }} />
                    Contato
                  </Typography>
                  {storeData.whatsapp ? (
                    <Typography variant="body1">{maskFormatter(storeData.whatsapp)}</Typography>
                  ) : null}
                  {storeData.phoneNumber ? (
                    <Typography variant="body1">{maskFormatter(storeData.phoneNumber)}</Typography>
                  ) : null}
                </>
              ) : null}
            </Grid>
          </Grid>

          <Grid id="location" sx={{ flex: 1, width: '100%', position: 'relative' }}>
            {isMobile ? (
              <Button
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={`http://maps.google.com/?q=${
                  storeData.placeCoordinates?.formattedAddress ||
                  storeData.coordinates?.formattedAddress
                }`}
                sx={{
                  ...styledButton,
                  width: 230,
                  margin: 'auto',
                }}
              >
                <RoomOutlinedIcon />
                <Typography variant="body1">Ver Rota para a loja</Typography>
              </Button>
            ) : null}

            {storeData.placeCoordinates || storeData.coordinates ? (
              <Location.Root
                name={storeData.placeName || storeData.name}
                coordinates={storeData.placeCoordinates || storeData.coordinates}
                embedGoogle={store.gmbMapSrc}
              />
            ) : null}
          </Grid>

          {isMobile ? (
            <Grid
              className={classes.contactsMobile}
              sx={{ flexDirection: 'column', padding: '8px 24px' }}
            >
              <Typography
                variant="h3"
                color="primary"
                sx={{ alignSelf: isMobile ? 'center' : 'flex-start' }}
              >
                <LocalPhoneOutlinedIcon style={{ fontSize: 28 }} />
                Contato
              </Typography>

              {storeData.phoneNumber ? (
                <Typography variant="body1" sx={{ alignSelf: isMobile ? 'center' : 'flex-start' }}>
                  {maskFormatter(storeData.phoneNumber)}
                </Typography>
              ) : null}

              {storeData.whatsapp ? (
                <Typography variant="body1" sx={{ alignSelf: isMobile ? 'center' : 'flex-start' }}>
                  {maskFormatter(storeData.whatsapp)}
                </Typography>
              ) : null}
            </Grid>
          ) : null}
        </Grid>

        <Grid component="footer" className={classes.footer}>
          <img src={`${URL_IMAGE_REPO_S3}/sooper/logo-group.svg`} alt="Logo Sooper Grupo" />
        </Grid>

        <Footer />
      </Grid>
    </>
  )
}
