import { CssBaseline } from '@mui/material'

import { AppRoutes } from './routes'
import { Theme } from './theme'
import { AppProvider } from './context/AppContext'

function App() {
  return (
    <AppProvider>
      <Theme>
        <CssBaseline />
        <AppRoutes />
      </Theme>
    </AppProvider>
  )
}

export default App
