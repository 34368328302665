import { createContext, useState, ReactNode } from 'react'

type AppContextData = {
  config: Record<string, any> | null
  setConfig: React.Dispatch<React.SetStateAction<object | null>>
}

export const AppContext = createContext({} as AppContextData)

type AppContextProviderProps = {
  children: ReactNode
}

export function AppProvider({ children }: AppContextProviderProps) {
  const [config, setConfig] = useState<object | null>(null)

  return <AppContext.Provider value={{ config, setConfig }}>{children}</AppContext.Provider>
}
