import { Button, Typography, ButtonProps } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

type ButtonWhatsAppProps = ButtonProps & {
  whatsapp: string
  backgroundColor: string
  text?: string
}

export function ButtonWhatsApp({ whatsapp, backgroundColor, text = '' }: ButtonWhatsAppProps) {
  const formattedWhatsapp =
    whatsapp.length >= 10 && !whatsapp.startsWith('55') ? `55${whatsapp}` : `${whatsapp}`

  return (
    <Button
      component="a"
      target="_blank"
      href={`https://api.whatsapp.com/send?phone=${formattedWhatsapp}`}
      variant="contained"
      disableElevation
      style={{
        gap: 8,
        display: 'flex',
        backgroundColor,
      }}
    >
      <WhatsAppIcon color="secondary" style={{ fontSize: 32 }} />
      <Typography
        color="secondary"
        style={{
          fontWeight: 500,
          textAlign: 'center',
          textTransform: 'initial',
        }}
      >
        {text || 'Peça pelo WhatsApp'}
      </Typography>
    </Button>
  )
}
