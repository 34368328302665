import { Button, ButtonProps } from '@mui/material'

import WhatsAppIcon from '@mui/icons-material/WhatsApp'

type ButtonWhatsAppProps = ButtonProps & {
  whatsapp: string
}

export function ButtonFloatingWhatsApp({ whatsapp }: ButtonWhatsAppProps) {
  const formattedWhatsapp =
    whatsapp.length >= 10 && !whatsapp.startsWith('55') ? `55${whatsapp}` : `${whatsapp}`

  function handleReirectWhatsApp(whatsapp: string): string {
    return `https://api.whatsapp.com/send?phone=${formattedWhatsapp}&text=Olá, tudo bem? Vi o contato no site e gostaria de informações.`
  }

  return (
    <Button
      component="a"
      target="_blank"
      href={handleReirectWhatsApp(whatsapp)}
      style={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        width: 64,
        height: 64,
        borderRadius: 9999,
        backgroundColor: '#FFF200',
        zIndex: 3147483644,
      }}
    >
      <WhatsAppIcon style={{ fontSize: 32, color: '#006437' }} />
    </Button>
  )
}
