import React from 'react'
import { Box, Typography } from '@mui/material'

type Card = {
  title: string
  description: string
  image: string
}

const titleStyle = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: '35px',
  letterSpacing: '.02em',
  color: '#0E3940',
  margin: '0 0 0.5rem',
}

const bodyStyle = {
  fontFamily: 'Kumbh Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '19px',
  letterSpacing: '.02em',
  color: '#fff',
  // textAlign: 'center',
}

const CardSingStapes = ({ title, description, image }: Card) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        background: '#93C400',
        borderRadius: '10px',
        padding: '1rem',
        maxWidth: '530px',
        margin: '0 auto',
      }}
      height="200px"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="60%"
      >
        <Typography style={titleStyle} align="center">
          {title}
        </Typography>
        <Typography style={bodyStyle} align="center">
          {description}
        </Typography>
      </Box>
      <Box width="40%" display="flex" alignItems="center" justifyContent="center">
        <img src={image} alt={description} style={{ maxWidth: '100%' }} />
      </Box>
    </Box>
  )
}

export default CardSingStapes
