import { Typography, TypographyProps, Tooltip } from '@mui/material'

type TextEllipsisProps = TypographyProps & {
  lineClamp?: number
  children: React.ReactNode | string
}

export function TextEllipsis({ children, lineClamp = 2, ...rest }: TextEllipsisProps) {
  return (
    <Tooltip title={children as string} placement="bottom" arrow>
      <Typography
        {...rest}
        style={{
          maxWidth: '100%',
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: lineClamp,
          ...rest?.style,
        }}
      >
        {children}
      </Typography>
    </Tooltip>
  )
}
