export function maskFormatter(value: string, pattern?: string) {
  if (!value) return

  let i = 0
  const v = value.toString()

  if (pattern) return pattern.replace(/#/g, () => v[i++] || '')

  switch (v.length) {
    case 8:
      return '####-####'.replace(/#/g, () => v[i++] || '')
    case 9:
      return '#####-####'.replace(/#/g, () => v[i++] || '')
    case 10:
      return '(##) ####-####'.replace(/#/g, () => v[i++] || '')
    case 11:
      return '(##) #####-####'.replace(/#/g, () => v[i++] || '')
  }
}
