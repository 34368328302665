import { useEffect } from 'react'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'

import { makeStyles } from '@mui/styles'
import { Button, Grid, Paper, Typography } from '@mui/material'

import PhoneIcon from '@mui/icons-material/Phone'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'

import { Loading } from '~/components/Loading'
import { Location } from '~/components/Location'
import { Products } from '~/components/Products'
import { ButtonRoute } from '~/components/Buttons/ButtonRoute'
import { ButtonWhatsApp } from '~/components/Buttons/ButtonWhatsApp'
import { ButtonPhone } from '~/components/Buttons/ButtonPhone'
import { Menu } from '~/components/Menu'
import { Footer } from '~/components/Footer'

import { useApp } from '~/hooks/useApp'
import { useFetch } from '~/hooks/useFetch'
import { useViewport } from '~/hooks/useViewport'

import { API, GMB } from '~/services/api'

import { GMBType, ComponentProps } from '~/types/app'
import { mountOptions } from '~/pages/helpers/mountOptions'
import { maskFormatter } from '~/utils/mask'
import { StoreSchema, StoreType } from '~/schemas/store'
import { refinePeriod, transformPeriod } from '~/utils/google-parse-period'

const useStyles = makeStyles((theme: any) => ({
  header: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    '& > p': {
      fontSize: 16,
      fontWeight: 700,
      color: '#444',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        borderBottom: '2px solid #F2F2F2',
        textTransform: 'uppercase',
        margin: '8px 0',
        padding: 4,
      },
    },
  },
  groupButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  banner: {
    height: '100%',
    '& img': {
      width: '100%',
      height: '100%',
      maxHeight: 485,
      objectFit: 'cover',
    },
    '& > a div': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#D9D9D920',
      padding: '37px 0 54px',
    },
  },
  cardInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '32px auto',
    padding: '48px 0',
    gap: 16,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '50%',
      height: 6,
      backgroundColor: theme?.palette?.primary?.main,
      [theme.breakpoints.down('md')]: {
        width: '0%',
      },
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      gap: 32,
    },
  },
  paper: {
    width: 350,
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    '& > p': {
      fontSize: 25,
    },
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    position: 'relative',

    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: 1200,
      zIndex: 2,
      margin: '0 auto',
      '& > div:first-child p': {
        fontSize: 20,
      },
    },
  },
}))

const URL_IMAGE_REPO = 'https://images.pertinhodemim.com/retailLandingPage'
const URL_IMAGE_REPO_S3 = 'https://s3.sa-east-1.amazonaws.com/gofind.img.repo/retailLandingPage'

function HeaderPage({ store, isMobile }: Omit<ComponentProps, 'gmb'>) {
  const classes = useStyles()

  const menuOptions = mountOptions(store)
  const fullAddress =
    store?.placeCoordinates?.formattedAddress || store?.coordinates?.formattedAddress

  return (
    <Grid component="header" className={classes.header}>
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        <Typography variant="body1">
          {store?.placeName || store?.name} {!isMobile ? '| Materiais de Construção' : ''}
        </Typography>

        <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '4px' }}>
          <p style={{ fontSize: 12, margin: 0 }}>Revendedor Oficial</p>
          <img
            src={`${URL_IMAGE_REPO_S3}/saint-gobain/-logo-quartzolit.png`}
            alt="Revendedor quartzolit"
          />
        </Grid>
      </Grid>

      {isMobile ? (
        <Menu
          menuIcon={<MoreVertIcon color="secondary" />}
          menuHorizontal="right"
          menuOptions={menuOptions}
        />
      ) : (
        <Grid className={classes.groupButtons}>
          {store?.whatsapp && (
            <ButtonWhatsApp whatsapp={store?.whatsapp} backgroundColor="#FCEC31" text="WhatsApp" />
          )}

          <Grid>
            <ButtonRoute
              address={fullAddress}
              text="Como chegar na loja"
              icon={<DirectionsRoundedIcon color="secondary" style={{ fontSize: 32 }} />}
            />
            <ButtonPhone
              icon={<PhoneIcon color="secondary" style={{ fontSize: 26, marginLeft: 4 }} />}
              text="Ligar para a loja"
              phone={store?.phoneNumber}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export function QuartzolitPage() {
  const classes = useStyles()

  const { isMobile } = useViewport()
  const { cnpj } = useParams<{ cnpj: string }>()
  const { setConfig } = useApp()

  const { data: store, fetching: isFetchingStore } = useFetch<StoreType>(`${API}/api`, cnpj)
  const { data: gmb, fetching: isFetchingGMB } = useFetch<GMBType | null>(
    `${GMB}/landingPage-location`,
    cnpj,
    {
      headers: { 'x-api-key': 'g5J>[Vchx4$&t`5~' },
    },
  )

  const storeData = (store && StoreSchema.parse(store)) || null
  const periodData =
    gmb?.data?.regularHours?.periods && Array.isArray(gmb?.data?.regularHours?.periods)
      ? gmb?.data?.regularHours?.periods
      : null

  const storePeriod = transformPeriod(periodData || [])
  const refineStorePeriod = storePeriod ? refinePeriod(storePeriod) : null
  const filterStorePeriodIsOpen = refineStorePeriod?.filter((p) => p.isOpen) || []

  const initDayOfWeekOpened = filterStorePeriodIsOpen?.[0]
  const lastDayOfWeekOpened = filterStorePeriodIsOpen?.[filterStorePeriodIsOpen.length - 1]

  useEffect(() => {
    setConfig({ colorPrimary: '#FCEC31', colorSecondary: '#000000', fontFamily: 'Gotham' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isFetchingStore || isFetchingGMB) return <Loading />

  if (!store || !storeData) return null

  return (
    <>
      <Helmet>
        <link rel="icon" href={`${URL_IMAGE_REPO}/saint-gobain/logo-mascote-medium.png`} />
        <title>{`Loja | ${storeData.placeName || storeData.name}`}</title>
        <link rel="canonical" href={window.location.href} />

        {/* Google Tag Manager - GOFIND */}
        <script>
          {`
            (function(w, d, s, l, i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l!= 'dataLayer' ? '&l='+l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-NDV6BFS');
          `}
        </script>
        {/* End · Google Tag Manager - GOFIND */}
      </Helmet>

      <Grid container component="main" style={{ maxWidth: 1380, margin: '0 auto' }}>
        <HeaderPage store={store} isMobile={isMobile} />

        <Grid container className={classes.banner}>
          <img
            src={`${URL_IMAGE_REPO_S3}/saint-gobain/banner-3.png`}
            alt="Banner principal"
            loading="lazy"
          />
          <a
            href="https://auth.parceirodaconstrucao.com.br/auth/realms/parceiro/protocol/openid-connect/auth?client_id=front&redirect_uri=https%3A%2F%2Fapp.parceirodaconstrucao.com.br%2F&state=l9359afxly55qf6m0bg&response_mode=query&response_type=code&utm_source=banner-site-gofind&utm_campaign=banner-site-gofind&utm_medium=banner-site-gofind&utm_term=&utm_content=&utm_id=banner-site-gofind&scope=openid&nonce=91620030&kc_idp_hint=undefined"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', width: '100%' }}
          >
            {isMobile ? (
              <img
                src={`${URL_IMAGE_REPO_S3}/saint-gobain/banners_secundarios-mobile.png`}
                alt="Banner secundario"
                loading="lazy"
              />
            ) : (
              <Grid>
                <img
                  src={`${URL_IMAGE_REPO_S3}/saint-gobain/banners_secundarios-mobile.png`}
                  alt="Banner secundario"
                  style={{ width: 538, height: 221 }}
                  loading="lazy"
                />
              </Grid>
            )}
          </a>
        </Grid>

        {gmb?.localPosts?.localPosts && (
          <Products
            title="Os melhores produtos pertinho de você"
            typeButton="knowMore"
            colorButton="primary"
            direction="row"
            products={gmb?.localPosts?.localPosts}
            elevation={0}
          />
        )}

        <Grid className={classes.cardInfo} style={{ maxWidth: isMobile ? '100%' : 1200 }}>
          {!!periodData && (
            <Paper elevation={0} className={classes.paper}>
              <img src={`${URL_IMAGE_REPO_S3}/saint-gobain/period-time.svg`} alt="período" />
              <Typography variant="body1">
                <strong>
                  Funcionamento <br /> de {initDayOfWeekOpened?.dayOfWeek?.replace(/-\w+/g, '')} a{' '}
                  <br /> {lastDayOfWeekOpened?.dayOfWeek?.replace(/-\w+/g, '')} das{' '}
                  {initDayOfWeekOpened?.open?.replace(/:\w+/g, 'h')} <br /> às{' '}
                  {lastDayOfWeekOpened?.close?.replace(/:\w+/g, 'h')?.trim()}
                </strong>
              </Typography>
            </Paper>
          )}

          <Paper elevation={0} className={classes.paper}>
            <img src={`${URL_IMAGE_REPO_S3}/saint-gobain/credit-card.svg`} alt="pagamento" />
            <Typography variant="body1">
              Parcelamento <br />
              <strong>facilitado</strong>
            </Typography>
          </Paper>

          <Paper elevation={0} className={classes.paper}>
            <img
              src={`${URL_IMAGE_REPO_S3}/saint-gobain/logo-mascote-medium.png`}
              alt="pagamento"
            />
            <Typography variant="body1">
              <strong>
                Revendedor
                <br />
                Oficial
                <br />
                Quartzolit
              </strong>
            </Typography>
          </Paper>
        </Grid>

        <Grid
          style={{
            width: '100%',
            padding: isMobile ? '16px 16px 0' : 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: 'linear-gradient(to bottom, #FFF 50%, #FCEC31 40%)',
          }}
        >
          {storeData.placeCoordinates || storeData.coordinates ? (
            <Location.Root
              name={storeData.placeName || storeData.name}
              coordinates={storeData.placeCoordinates || storeData.coordinates}
              embedGoogle={storeData.gmbMapSrc}
              width={1200}
              height={500}
            >
              <Location.Title
                sx={{
                  fontSize: '29px',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Location.Icon>
                  <RoomOutlinedIcon color="secondary" style={{ fontSize: 40 }} />
                </Location.Icon>
                <Location.Text>Onde estamos</Location.Text>
              </Location.Title>

              <Typography
                variant="body1"
                align="center"
                color="secondary"
                sx={{ marginBlock: '16px', fontSize: '25px' }}
              >
                {storeData.placeCoordinates?.formattedAddress ||
                  storeData.coordinates?.formattedAddress}
              </Typography>

              <Button
                component="a"
                variant="text"
                href={`http://maps.google.com/?q=${
                  storeData.placeCoordinates?.formattedAddress ||
                  storeData.coordinates?.formattedAddress
                }`}
                target="_blank"
                color="secondary"
                style={{
                  fontSize: 25,
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  textTransform: 'none',
                }}
              >
                Rota para loja
              </Button>
            </Location.Root>
          ) : null}

          <Grid className={classes.footer} style={{ marginTop: 24 }}>
            <Grid
              style={{
                textAlign: isMobile ? 'center' : 'left',
                gap: isMobile ? 16 : 0,
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: isMobile ? 'center' : 'space-between',
              }}
            >
              <Grid
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: '32px',
                }}
              >
                <Grid>
                  <Typography variant="body1">QUARTZO | Materiais de Construção</Typography>
                  <Grid style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                    {storeData.whatsapp ? (
                      <Typography variant="body1">{maskFormatter(storeData.whatsapp)}</Typography>
                    ) : null}

                    {storeData.phoneNumber ? (
                      <Grid style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <WhatsAppIcon />
                        <Typography variant="body1">{maskFormatter(store?.phoneNumber)}</Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <img
                    src={`${URL_IMAGE_REPO_S3}/saint-gobain/logo-mascote-small.png`}
                    alt="mascote"
                  />
                  <Typography variant="body1">
                    <strong>
                      Revendedor <br /> Oficial <br /> Quartzolit
                    </strong>
                  </Typography>
                </Grid>
              </Grid>

              <img
                src={`${URL_IMAGE_REPO_S3}/saint-gobain/logo-container.png`}
                alt="logo quartzolit"
              />
            </Grid>
          </Grid>
        </Grid>

        <Footer />
      </Grid>
    </>
  )
}
