import { Box, Typography } from '@mui/material'

type CardProps = {
  image: string
  text: string
}

const CardGoogle = ({ image, text }: CardProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="35px"
      justifyContent="center"
      alignItems="center"
    >
      <img src={image} alt={text} style={{ maxWidth: '100%', height: 'auto' }} />
      <Typography
        sx={{
          fontFamily: 'Raleway',
          fontSize: '22px',
          fontStyle: 'normal',
          lineHeight: '25px',
          letterSpacing: '.02em',
          textAlign: 'center',
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default CardGoogle
