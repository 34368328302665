import { useEffect, useState } from 'react'
import axios from 'axios'

import { useQuery } from '../hooks/useQuery'

export function useFetch<T = unknown>(url: string, params: any, options?: any) {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<Error | null | unknown>(null)
  const [fetching, setFetching] = useState<boolean>(false)

  const query = useQuery()

  useEffect(() => {
    ;(async () => {
      const search = query.get('cnpj')

      try {
        setFetching(true)

        const queryString = params === 'index.html' ? search : params

        const response = await axios.get(`${url}/${queryString}`, { ...options })

        const { data } = response

        setData({ ...data })
      } catch (err) {
        console.log('TCL: ~ useFetch ~ IIFE (useEFfect) ~ err', err)
        setError(err)
      } finally {
        setFetching(false)
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return { data, fetching, error }
}
