import { useRef, useEffect, ReactNode } from 'react'

import { styled } from '@mui/system'
import { Grid, GridProps } from '@mui/material'

import { Coordinates } from '~/types/app'

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken =
  'pk.eyJ1IjoibG9obWFubiIsImEiOiJjanlweGw5djQxa2c4M2pvOXl1emZtcWJhIn0.T2p0aBm9yZc7SnEr0jiHUw'

const GmbMapIframe = styled('iframe')({
  height: '412px',
  width: 'inherit',
  maxWidth: '985px',
  border: 0,
})

interface RootProps extends GridProps {
  name: string
  width?: number | string
  height?: number | string
  children?: ReactNode
  embedGoogle?: string | null
  coordinates: Coordinates | null
}

export function Root({
  children,
  coordinates,
  name,
  embedGoogle,
  width = 985,
  height = 412,
  ...props
}: RootProps) {
  const mapContainer = useRef<HTMLDivElement>(null)

  const [lat, lon] = coordinates?.latlon?.split(',') || [0, 0]

  useEffect(() => {
    if (!mapContainer.current) return

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [Number(lon), Number(lat)],
      zoom: 13,
    })

    new mapboxgl.Marker({ color: '#FF0000' })
      .setLngLat([Number(lon), Number(lat)])
      .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<p>${name}</p>`))
      .addTo(map)
    map.addControl(new mapboxgl.NavigationControl())
  }, [lat, lon, name])

  return (
    <Grid
      container
      id="location"
      component="section"
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ padding: 16 }}
      {...props}
    >
      {children}

      {!!children ? <br /> : null}

      {embedGoogle ? (
        <GmbMapIframe src={embedGoogle} loading="lazy" />
      ) : (
        <div
          ref={mapContainer}
          className="map-container"
          style={{ width: '100%', height: height, maxWidth: width }}
        />
      )}
    </Grid>
  )
}
