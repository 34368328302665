const productCars = [
  {
    name: 'bateria original',
    image: 'original.png',
    description:
      'A linha Heliar Original conta com tecnologia PowerFrame e 24 meses de garantia. É a bateria aprovada mundialmente pelas montadoras.',
  },
  {
    name: 'bateria superfree',
    image: 'superfree.png',
    description:
      'A linha Heliar Heliar Super Free tem tecnologia PowerFrame, o que garante mais resistência e autonomia para sua bateria. Tem 02 anos de garantia, a maior do mercado e a exclusiva Proteção 24 horas Heliar.',
  },
  {
    name: 'bateria agm',
    image: 'heliar-agm.png',
    description:
      ' A linha Heliar AGM é ideal para veículos altamente equipados e também para sistemas Start-stop. Tem 24 meses de garantia e Proteção 24 horas Heliar.',
  },
  {
    name: 'bateria efb',
    image: 'heliar-efb.png',
    description:
      'A Heliar EFB é indicada para veículos Start-Stop com alternadores inteligentes. Além disso é equipamento original nas montadoras que utilizam essa tecnologia.',
  },
]

const productMotorcycles = [
  {
    name: 'bateria powersports',
    title: 'Heliar PowerSports',
    image: 'heliar-moto-3.png',
    description: 'É sua moto com máximo desempenho. Original das principais motos do mercado.',
  },
  {
    name: 'bateria extreme',
    title: 'Extreme',
    image: 'heliar-moto-1.png',
    description:
      'A bateria Heliar Extreme tem 12 meses de garantia, qualidade superior comprovada e a exclusiva tecnologia AGM, que já vem pronta para uso.',
  },
  {
    name: 'bateria dry charged',
    title: 'Heliar Dry Charged',
    image: 'heliar-moto-2.png',
    description:
      'As baterias da linha Heliar Powersports Dry Charged oferece maior durabilidade e desempenho a motos. Com tecnologia AGM, elas têm 6 meses de garantia.',
  },
]

export { productCars, productMotorcycles }
