import { Grid } from '@mui/material'

export function Divider() {
  return (
    <Grid
      container
      style={{
        height: 10,
        background:
          'transparent linear-gradient(90deg, #fff200 0%, #EC8014 50%, #d92027 100%) 0% 0% no-repeat padding-box',
      }}
    />
  )
}
