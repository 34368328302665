import { ReactNode, MouseEvent, useState, useEffect } from 'react'
import { Link, Events } from 'react-scroll'
import { IconButton, Menu as MenuMUI, MenuItem, ListItemIcon, Typography } from '@mui/material'

export type Options = {
  id?: string
  icon?: ReactNode
  label?: string | undefined
  handler?: (() => void) | undefined
}

type MenuDropdownProps = {
  menuIcon: ReactNode
  menuHorizontal: number | 'left' | 'center' | 'right'
  menuOptions: Array<Options | undefined> | undefined
}

export function Menu({ menuIcon, menuHorizontal, menuOptions }: MenuDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  useEffect(() => {
    Events.scrollEvent.register('begin', function () {
      console.log('begin', arguments)
    })

    Events.scrollEvent.register('end', function () {
      console.log('end', arguments)
    })

    return () => {
      Events.scrollEvent.remove('begin')
      Events.scrollEvent.remove('end')
    }
  }, [])

  return (
    <>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {menuIcon}
      </IconButton>
      <MenuMUI
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: menuHorizontal, vertical: 'top' }}
        anchorOrigin={{ horizontal: menuHorizontal, vertical: 'top' }}
      >
        {menuOptions?.filter(Boolean)?.map((option) => (
          <Link key={option?.id} to={String(option?.id)} smooth={true} duration={500} offset={-70}>
            <MenuItem onClick={option?.handler}>
              <ListItemIcon>
                {option?.icon}
                <Typography color="secondary">{option?.label}</Typography>
              </ListItemIcon>
            </MenuItem>
          </Link>
        ))}
      </MenuMUI>
    </>
  )
}
