import { useEffect } from 'react'
import { Grid, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    width: '100%',
  },
  content: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 32,
  },
  title: {
    fontSize: 40,
  },
})

export function NoMatch() {
  const classes = useStyles()

  useEffect(() => {
    document.title = 'Página não encontrada.'
  }, [])

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Grid item className={classes.content}>
        <h1 className={classes.title}>Erro 404</h1>
        <Divider orientation="vertical" />
        <span>Esta página não foi encontrada.</span>
      </Grid>
      <span style={{ marginTop: 32, color: '#999' }}>
        <strong>acesse:</strong> https://www.lojalocal.com/seu-cnpj
      </span>
      <span style={{ color: '#999', fontStyle: 'italic' }}>
        {' '}
        Ex: https://www.lojalocal.com/12345678000190
      </span>
    </Grid>
  )
}
