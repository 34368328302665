export enum DAYS {
  SUNDAY = 'Domingo',
  MONDAY = 'Segunda-feira',
  TUESDAY = 'Terça-feira',
  WEDNESDAY = 'Quarta-feira',
  THURSDAY = 'Quinta-feira',
  FRIDAY = 'Sexta-feira',
  SATURDAY = 'Sábado',
}

export type Day = keyof typeof DAYS

export type Time = {
  hours: number
  minutes?: number
}

export type Period = {
  openDay: Day
  openTime: Time
  closeDay: Day
  closeTime: Time
}

export type PeriodOutput = {
  dayOfWeek: string
  open: string
  close: string
  isOpen: boolean
}

const transformTime = (time: Time): string => {
  const { hours = 0, minutes = 0 } = time

  const strHours = hours ? String(hours) : ''
  const strMinutes = minutes ? String(minutes) : ''

  return strHours.padStart(2, '0') + (strMinutes ? `:${strMinutes.padStart(2, '0')}` : ':00')
}

export const transformPeriod = (period: Period[]): PeriodOutput[] | null => {
  if (!period.length) return null

  return period.map((p: Period) => {
    const dayOfWeek: string = DAYS[p.openDay || p.closeDay]

    return {
      dayOfWeek: dayOfWeek,
      open: transformTime(p.openTime),
      close: transformTime(p.closeTime),
      isOpen: true,
    }
  })
}

export const refinePeriod = (period: PeriodOutput[]): PeriodOutput[] | null => {
  if (!period.length) return null

  const keyDays: string[] = Object.values(DAYS)

  const checkRemainingPeriods = keyDays
    .map((day: string) => period.find((p: PeriodOutput) => p.dayOfWeek === day))
    .map(
      (p: PeriodOutput | undefined, index: number) =>
        p ?? { dayOfWeek: keyDays[index], open: 'fechado', close: 'fechado', isOpen: false },
    )

  return checkRemainingPeriods
}
