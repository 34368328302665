import { z } from 'zod'

export const StoreSchema = z.object({
  cnpj: z.string(),
  name: z.string(),
  logo: z.string(),
  coordinates: z
    .object({
      latlon: z.string().regex(/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/),
      formattedAddress: z.string(),
    })
    .nullable(),
  gmbMapSrc: z.string(),
  phoneNumber: z.string(),
  whatsapp: z.string(),
  placeCoordinates: z
    .object({
      latlon: z.string().regex(/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/),
      formattedAddress: z.string(),
    })
    .nullable(),
  placeName: z.string(),
})

export type StoreType = z.infer<typeof StoreSchema>
