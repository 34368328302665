import { useState } from 'react'
import { Grid, GridProps, Typography } from '@mui/material'

import { useViewport } from '~/hooks/useViewport'

type BannerProps = GridProps & {
  storeName?: string
  backgroundImage?: string
}

export function Banner({ backgroundImage, storeName, ...rest }: BannerProps) {
  const { isMobile } = useViewport()
  const [fallbackImage, setFallbackImage] = useState<boolean>(false)

  return (
    <Grid
      {...rest}
      style={{
        position: isMobile ? 'relative' : 'absolute',
        height: '100%',
        transform: isMobile ? 'translateY(0%)' : 'translateY(-80%)',
        paddingInline: isMobile ? 0 : 48,
        ...rest?.style,
      }}
    >
      {(!backgroundImage || fallbackImage) && !isMobile && (
        <Typography
          variant="h1"
          style={{
            position: 'absolute',
            top: '50%',
            left: '15%',
            transform: 'translateY(-50%)',
            fontSize: 44,
            fontWeight: 700,
          }}
        >
          {storeName}
        </Typography>
      )}

      <img
        src={backgroundImage}
        alt="Banner"
        style={{
          width: '100%',
          height: '100%',
          maxHeight: 485,
        }}
        loading="lazy"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = 'https://images.pertinhodemim.com/retailLandingPage/banner-pets.png'

          setFallbackImage(true)
        }}
      />
    </Grid>
  )
}
