import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'

import { Grid, Typography, TextField, Divider, Container } from '@mui/material'
import { useQuery } from '../hooks/useQuery'
import { useApp } from '../hooks/useApp'

export function SelectCnpj() {
  const { setConfig } = useApp()
  const [cnpj, setCnpj] = useState<string>('')
  const navigate = useNavigate()
  const query = useQuery()
  const logoBatery = 'https://images.pertinhodemim.com/retailLandingPage/logo_bateria.png'
  const urlOrigin = window.location.origin.concat('/')
  const search = query.get('cnpj')

  useEffect(() => {
    document.title = 'Loja | Home'

    setConfig({ colorPrimary: '#006437', colorSecondary: '#006437' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (search) navigate(`/${search}`)

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid
          item
          sx={{
            width: '600px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '16px',
            paddingBottom: '8px',
          }}
        >
          <img src={logoBatery} alt="Logo" loading="lazy" />
          <Typography
            variant="h1"
            style={{ color: '#006437' }}
            sx={{
              fontWeight: 400,
              fontSize: {
                xs: '4rem',
                sm: '6rem',
              },
            }}
          >
            Heliar
          </Typography>
          <img src={logoBatery} alt="Logo" loading="lazy" />
        </Grid>
        <div style={{ width: '100%', borderBottom: '3px solid #006437' }} />

        <Grid item sx={{ width: '100%', display: 'flex' }}>
          <TextField
            label="Informe seu cnpj e acesse o link abaixo"
            variant="outlined"
            sx={{ marginTop: '24px', width: '100%' }}
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
          >
            <InputMask mask="99.999.999/9999-99" />
          </TextField>
        </Grid>

        <Divider style={{ width: '100%', margin: '24px 0' }} />

        <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
          <Typography variant="body1" style={{ color: '#999' }}>
            Acesse:
          </Typography>
          <div>
            <a
              href={`${urlOrigin}${cnpj.replace(/\D/g, '')}`}
              rel="noopener noreferrer"
              style={{ color: '#006437' }}
            >
              {`${urlOrigin}${cnpj.replace(/\D/g, '')}`}
            </a>{' '}
            <br />
            <a
              href={`${urlOrigin}carros/${cnpj.replace(/\D/g, '')}`}
              rel="noopener noreferrer"
              style={{ color: '#006437' }}
            >
              {`${urlOrigin}carros/${cnpj.replace(/\D/g, '')}`}
            </a>{' '}
            <br />
            <a
              href={`${urlOrigin}motos/${cnpj.replace(/\D/g, '')}`}
              rel="noopener noreferrer"
              style={{ color: '#006437' }}
            >
              {`${urlOrigin}motos/${cnpj.replace(/\D/g, '')}`}
            </a>{' '}
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
