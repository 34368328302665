import { ReactNode } from 'react'
import { Typography, Button } from '@mui/material'

interface ButtonRouteProps {
  address: string | undefined
  text?: string
  icon?: ReactNode
}

export function ButtonRoute({ address, text, icon }: ButtonRouteProps) {
  return (
    <Button
      component="a"
      target="_blank"
      rel="noopener noreferrer"
      href={`http://maps.google.com/?q=${address}`}
      startIcon={icon}
    >
      <Typography
        component="span"
        color="secondary"
        style={{
          fontSize: 16,
          textTransform: 'initial',
          textDecoration: 'underline',
        }}
      >
        {text || 'Ver rota para loja'}
      </Typography>
    </Button>
  )
}
