import { useEffect } from 'react'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'

import { Avatar, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import PhoneIcon from '@mui/icons-material/Phone'
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded'
import MenuIcon from '@mui/icons-material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { Banner } from '~/components/Banner'
import { Loading } from '~/components/Loading'
import { Location } from '~/components/Location'
import { DialogTime } from '~/components/DialogTime'
import { Reviews } from '~/components/Reviews'
import { Gallery } from '~/components/Gallery'
import { Products } from '~/components/Products'
import { ButtonRoute } from '~/components/Buttons/ButtonRoute'
import { ButtonWhatsApp } from '~/components/Buttons/ButtonWhatsApp'
import { ButtonPhone } from '~/components/Buttons/ButtonPhone'
import { TextEllipsis } from '~/components/TextEllipsis'
import { Menu } from '~/components/Menu'
import { Footer } from '~/components/Footer'

import { useApp } from '~/hooks/useApp'
import { useFetch } from '~/hooks/useFetch'
import { useViewport } from '~/hooks/useViewport'

import { API, GMB } from '~/services/api'
import { GMBType, ComponentProps } from '~/types/app'
import { StoreSchema, StoreType } from '~/schemas/store'

import { mountOptions } from './helpers/mountOptions'
import { RoomOutlined } from '@mui/icons-material'
import { refinePeriod, transformPeriod } from '~/utils/google-parse-period'

const URL_IMAGE_REPO = 'https://images.pertinhodemim.com/retailLandingPage'
const URL_IMAGE_REPO_S3 = 'https://s3.sa-east-1.amazonaws.com/gofind.img.repo/retailLandingPage'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    width: '100%',
    padding: '16px 8px',
    position: 'sticky',
    boxShadow: '0px 4px 2px -2px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFF',
    zIndex: 100,
    top: 0,
    '& div:first-child': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      '& span': {
        fontWeight: 500,
        textTransform: 'capitalize',
        textAlign: 'center',
      },
    },
  },
  storeLogo: {
    width: 100,
    height: 50,
    margin: '0 8px',
  },
  groupButtons: {
    display: 'flex',
    gap: 8,
  },
  paper: {
    width: 300,
    height: 308,
    padding: '24px 16px',
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div h3': {
      fontSize: 18,
      fontWeight: 400,
      marginTop: 24,
      textAlign: 'center',
    },
  },
  avatar: {
    margin: '0 auto',
    padding: 40,

    '& .MuiAvatar-img': {
      width: 'unset',
      height: 'unset',
    },
  },
  cardInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 24,
  },
  cardProduct: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 8,
  },
  footer: {
    backgroundColor: '#EDEBEB',
    width: '100%',
    padding: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
  },
})

function HeaderPage({ store, gmb, isMobile }: ComponentProps) {
  const classes = useStyles()

  const { logo, whatsapp = '', phoneNumber = '' } = store
  const fullAddress =
    store?.placeCoordinates?.formattedAddress || store?.coordinates?.formattedAddress

  const menuGeneral = [
    gmb?.localPosts?.localPosts
      ? {
          id: 'products',
          label: 'Produtos que você encontra aqui',
        }
      : undefined,
    gmb?.reviews?.reviews
      ? {
          id: 'about',
          label: 'O que falam sobre a gente',
        }
      : undefined,
    gmb?.media?.mediaItems
      ? {
          id: 'gallery',
          label: 'Galeria de fotos',
        }
      : undefined,
    {
      id: 'location',
      label: 'Onde estamos',
    },
  ]

  const menuOptions = mountOptions(store)

  return (
    <Grid container component="header" className={classes.header}>
      <Grid>
        <Menu
          menuIcon={<MenuIcon color="secondary" />}
          menuHorizontal="left"
          menuOptions={menuGeneral}
        />

        {logo ? (
          <img src={logo} alt="Logo" loading="lazy" className={classes.storeLogo} />
        ) : (
          <img src={`${URL_IMAGE_REPO}/logos/logo-organnact-nova.png`} alt="Logo" loading="lazy" />
        )}

        <Typography component="span" color="secondary">
          {store?.placeName || store?.name}
        </Typography>
      </Grid>

      {isMobile ? (
        <Menu
          menuIcon={<MoreVertIcon color="primary" />}
          menuHorizontal="right"
          menuOptions={menuOptions}
        />
      ) : (
        <Grid className={classes.groupButtons}>
          {phoneNumber && (
            <ButtonPhone
              icon={<PhoneIcon color="primary" style={{ fontSize: 28 }} />}
              textSuffix="Telefone:"
              phone={store?.phoneNumber}
            />
          )}

          <ButtonRoute
            address={fullAddress}
            icon={<DirectionsRoundedIcon color="primary" style={{ fontSize: 32 }} />}
          />

          {whatsapp && <ButtonWhatsApp whatsapp={whatsapp} backgroundColor="#EF9917" />}
        </Grid>
      )}
    </Grid>
  )
}

export function PetsPage() {
  const classes = useStyles()

  const { isMobile } = useViewport()
  const { cnpj } = useParams<{ cnpj: string }>()
  const { setConfig } = useApp()

  const { data: store, fetching: isFetchingStore } = useFetch<StoreType>(`${API}/api`, cnpj)
  const { data: gmb, fetching: isFetchingGMB } = useFetch<GMBType | null>(
    `${GMB}/landingPage-location`,
    cnpj,
    {
      headers: { 'x-api-key': 'g5J>[Vchx4$&t`5~' },
    },
  )

  const storeData = (store && StoreSchema.parse(store)) || null
  const periodData =
    gmb?.data?.regularHours?.periods && Array.isArray(gmb?.data?.regularHours?.periods)
      ? gmb?.data?.regularHours?.periods
      : null

  const storePeriod = transformPeriod(periodData || [])
  const refineStorePeriod = storePeriod ? refinePeriod(storePeriod) : null

  const currentDay = new Date().getDay()
  const currentPeriod = refineStorePeriod ? refineStorePeriod[currentDay] : null

  const bannerImage = gmb?.media?.mediaItems?.filter(
    (media: any) => media.locationAssociation.category === 'COVER',
  )

  useEffect(() => {
    setConfig({ colorPrimary: '#EF9917', colorSecondary: '#322B2D' })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isFetchingStore || isFetchingGMB) return <Loading />

  if (!store || !storeData) return null

  return (
    <>
      <Helmet>
        <link rel="icon" href={`${URL_IMAGE_REPO}/logos/logo-organnact-nova.png`} />
        <title>{`Loja | ${storeData.placeName || storeData.name}`}</title>
        <link rel="canonical" href={window.location.href} />

        {/* Google Tag Manager - GOFIND */}
        <script>
          {`
            (function(w, d, s, l, i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l!= 'dataLayer'? '&l='+l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-NDV6BFS');
          `}
        </script>
        {/* End · Google Tag Manager - GOFIND */}
      </Helmet>

      <div style={{ width: '100%' }}>
        <HeaderPage store={store} gmb={gmb} isMobile={isMobile} />

        {isMobile && (
          <Banner
            backgroundImage={bannerImage?.[0]?.thumbnailUrl || ''}
            storeName={storeData.placeName || storeData.name}
          />
        )}

        <Grid
          container
          component="main"
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            paddingTop: 40,
            marginBottom: 32,
            marginTop: isMobile ? 0 : 242.5,
            position: 'relative',
            background: 'linear-gradient(to bottom, #EFEFEF, #FFF)',
          }}
        >
          {!isMobile && (
            <Banner
              backgroundImage={bannerImage?.[0]?.thumbnailUrl || ''}
              storeName={storeData.placeName || storeData.name}
            />
          )}

          <Grid item className={classes.cardInfo}>
            {!!periodData && (
              <Paper className={classes.paper}>
                <Grid>
                  <Avatar
                    src={`${URL_IMAGE_REPO_S3}/icon-calendar.svg`}
                    alt="período"
                    className={classes.avatar}
                    sx={{ width: 56, height: 56, background: '#e3e3e3', zIndex: 9 }}
                  />
                  <Typography variant="h3" color="secondary">
                    {currentPeriod?.isOpen ? (
                      <>Aberto hoje até às {currentPeriod?.close}</>
                    ) : (
                      'Fechado'
                    )}
                  </Typography>
                </Grid>
                <DialogTime periods={refineStorePeriod} />
              </Paper>
            )}

            <Paper className={classes.paper}>
              <Grid>
                <Avatar
                  src={`${URL_IMAGE_REPO_S3}/icon-store.svg`}
                  alt="loja"
                  className={classes.avatar}
                  sx={{ width: 56, height: 56, background: '#e3e3e3', zIndex: 9 }}
                />
                <TextEllipsis lineClamp={3} variant="h3" color="secondary">
                  {storeData.placeCoordinates?.formattedAddress ||
                    storeData.coordinates?.formattedAddress}
                </TextEllipsis>
              </Grid>
              <ButtonRoute
                address={
                  storeData.placeCoordinates?.formattedAddress ||
                  storeData.coordinates?.formattedAddress
                }
                icon={<DirectionsRoundedIcon color="primary" style={{ fontSize: 32 }} />}
              />
            </Paper>

            {(storeData.whatsapp || storeData.phoneNumber) && (
              <Paper className={classes.paper}>
                <Grid>
                  <Avatar
                    src={`${URL_IMAGE_REPO_S3}/icon-money.svg`}
                    alt="orçamento"
                    className={classes.avatar}
                    sx={{ width: 56, height: 56, background: '#e3e3e3', zIndex: 9 }}
                  />
                  <Typography variant="h3" color="secondary">
                    Solicitar <br /> orçamentos
                  </Typography>
                </Grid>
                {storeData.whatsapp ? (
                  <ButtonWhatsApp whatsapp={storeData.whatsapp} backgroundColor="#EF9917" />
                ) : (
                  <ButtonPhone
                    icon={<PhoneIcon color="primary" style={{ fontSize: 28 }} />}
                    textSuffix="Tel:"
                    phone={storeData.phoneNumber}
                  />
                )}
              </Paper>
            )}
          </Grid>
        </Grid>

        {gmb?.localPosts?.localPosts ? (
          <Products
            direction={isMobile ? 'row' : 'column'}
            title="Produtos que você encontra aqui"
            products={gmb.localPosts.localPosts}
          />
        ) : null}

        {gmb?.reviews?.reviews ? (
          <Reviews
            reviewers={gmb.reviews.reviews}
            storeName={storeData.placeName || storeData.name}
            storeAddress={
              storeData.placeCoordinates?.formattedAddress ||
              storeData.coordinates?.formattedAddress
            }
            background="#F5F3F3"
          />
        ) : null}

        {gmb?.media?.mediaItems ? <Gallery images={gmb?.media?.mediaItems} /> : null}

        <Location.Root
          name={storeData.placeName || storeData.name}
          coordinates={storeData.placeCoordinates || storeData.coordinates}
          embedGoogle={storeData.gmbMapSrc}
        >
          <Location.Title
            sx={{ fontSize: '24px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
          >
            <Location.Text>Onde estamos</Location.Text>
          </Location.Title>

          <Typography variant="body1" align="center" color="secondary" sx={{ marginBlock: '16px' }}>
            {storeData.placeCoordinates?.formattedAddress ||
              storeData.coordinates?.formattedAddress}
          </Typography>

          <ButtonRoute
            address={
              storeData.placeCoordinates?.formattedAddress ||
              storeData.coordinates?.formattedAddress
            }
            icon={<RoomOutlined color="secondary" style={{ fontSize: 24 }} />}
          />
        </Location.Root>

        <Grid
          component="footer"
          className={classes.footer}
          sx={{ flexDirection: isMobile ? 'column' : 'row' }}
        >
          <img
            src="https://images.pertinhodemim.com/retailLandingPage/logos/logo-organnact-nova.png"
            alt="Logo"
            loading="lazy"
          />
          <Typography variant="body1" style={{ fontSize: 14 }}>
            Acredita nesse negócio
          </Typography>
        </Grid>

        <Footer />
      </div>
    </>
  )
}
