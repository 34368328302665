import { Grid, Typography } from '@mui/material'

export function Footer() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ gap: '8px', padding: '8px 0' }}
    >
      <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: '500' }}>
        Desenvolvido por
      </Typography>
      <a
        href="https://www.gofind.online/"
        rel="noopener noreferrer"
        target="_blank"
        style={{ height: 42, textDecoration: 'none' }}
      >
        <img
          src="https://images.pertinhodemim.com/gmb-reports/newslatter/nova-gofind.png"
          alt="logo Go.Find"
          width="85px"
          height="100%"
        />
      </a>
    </Grid>
  )
}
