import { useState } from 'react'

import { Container, Grid, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'

import { formatDistance } from 'date-fns'
import ptBr from 'date-fns/locale/pt-BR'

import { TextEllipsis } from '~/components/TextEllipsis'
import { useViewport } from '~/hooks/useViewport'

export const STAR_RATE = { ONE: 1, TWO: 2, THREE: 3, FOUR: 4, FIVE: 5 }

export type Review = {
  reviewer: {
    displayName: string
  }
  starRating: keyof typeof STAR_RATE
  comment: string
  createTime: string
}

type ReviewerProps = {
  background?: string
  storeName?: string
  storeAddress?: string
  reviewers: Array<Review> | undefined
}

const useStyles = makeStyles({
  comments: {
    width: '100%',
    margin: '32px 0',
    padding: 16,
    maxHeight: 350,
    borderBottom: '1px solid #CCC',

    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: 32,

    overflow: 'auto',

    '&::-webkit-scrollbar': { width: 8 },
    '&::-webkit-scrollbar-track': { backgroundColor: '#F1F1F1' },
    '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, .6)' },
  },
  comment: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  starRate: {
    display: 'flex',
    marginBottom: 24,
  },
  textComment: {
    textAlign: 'center',
  },
})

export function Reviews({
  background = 'none',
  reviewers,
  storeName = '',
  storeAddress = '',
}: ReviewerProps) {
  const classes = useStyles()
  const { isMobile } = useViewport()

  const [moreComments, setMoreComments] = useState<number>(2)

  const hasShowReviews = 2 < Number(reviewers?.length)
  const filteredReviews = reviewers?.filter(
    (reviewer) => reviewer.comment && ['THREE', 'FOUR', 'FIVE'].includes(reviewer.starRating),
  )

  const handleResetComments = () => {
    setMoreComments(2)
  }

  const handleMoreComments = () => {
    setMoreComments((prevComments) => prevComments + 2)
  }

  return (
    <Container
      id="about"
      component="section"
      style={{ backgroundColor: background, maxWidth: '100%' }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ padding: '40px 0', maxWidth: 800, margin: '0 auto' }}
      >
        <Typography variant="h5" style={{ fontWeight: 700 }} gutterBottom>
          O que falam sobre a gente
        </Typography>

        <Grid className={classes.comments}>
          {filteredReviews
            ?.map((review, index) => (
              <Grid key={index} className={classes.comment} style={{ flexDirection: 'column' }}>
                <Grid className={classes.starRate}>
                  {Array.from({ length: STAR_RATE[review.starRating] }).map((_, i) => (
                    <StarIcon key={i} color="primary" style={{ fontSize: 32 }} />
                  ))}
                  {Array.from({ length: 5 - STAR_RATE[review.starRating] }).map((_, i) => (
                    <StarBorderIcon key={i} color="primary" style={{ fontSize: 32 }} />
                  ))}
                </Grid>

                <TextEllipsis
                  lineClamp={5}
                  className={classes.textComment}
                  variant="body1"
                  gutterBottom
                >
                  <q>
                    {review.comment.includes('Translated')
                      ? review.comment
                          .replace(/[\r\n]/g, '')
                          .replace(/\(Translated by Google\)(.*)\(Original\)/gi, '')
                          .trim()
                      : review.comment}
                  </q>
                </TextEllipsis>

                <Grid style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
                  <Typography component="span" style={{ fontWeight: 700 }}>
                    {review.reviewer.displayName}
                  </Typography>
                  /
                  <Typography component="span">
                    Há {formatDistance(new Date(review.createTime), new Date(), { locale: ptBr })}
                  </Typography>
                </Grid>
              </Grid>
            ))
            .slice(0, moreComments)}
        </Grid>

        <Grid style={{ display: 'flex', gap: 16, flexDirection: isMobile ? 'column' : 'row' }}>
          <Button
            component="a"
            target="_blank"
            href={`https://www.google.com/maps?q=${storeName},${storeAddress}`}
            variant="outlined"
            style={{ width: 200, textTransform: 'none', color: '#000' }}
          >
            Deixe um comentário
          </Button>

          {hasShowReviews && (
            <>
              {moreComments >= Number(filteredReviews?.length) ? (
                <Button
                  variant="outlined"
                  style={{
                    width: 200,
                    textTransform: 'none',
                    color: '#000',
                  }}
                  onClick={handleResetComments}
                >
                  Ver menos comentários
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  style={{
                    width: 200,
                    textTransform: 'none',
                    color: '#000',
                  }}
                  onClick={handleMoreComments}
                >
                  Ver mais comentários
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
