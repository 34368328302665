export const handleWhatsApp = (whatsapp: string) => {
  const numberWhatsapp =
    whatsapp.length >= 10 && !whatsapp.startsWith('55') ? `55${whatsapp}` : whatsapp

  window.open(`https://api.whatsapp.com/send?phone=${numberWhatsapp}`, '_blank')
}

export const handlePhoneNumber = (phoneNumber: string) =>
  window.open(`tel:${phoneNumber}`, '_blank')

export const handleMapsRoute = (address: string | undefined) =>
  window.open(`http://maps.google.com/?q=${address}`, '_blank')
