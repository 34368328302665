import { ReactNode } from 'react'
import { Typography, TypographyProps } from '@mui/material'

interface TitleProps extends TypographyProps {
  children: ReactNode
}

export function Title({ children, ...props }: TitleProps) {
  return (
    <Typography variant="h2" color="secondary" {...props}>
      {children}
    </Typography>
  )
}
