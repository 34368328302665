import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button, Grid, IconButton, SvgIcon, SvgIconProps, Typography } from '@mui/material'
import { Facebook, Instagram, LinkedIn, Twitter, YouTube } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { Helmet } from 'react-helmet'

// custom components
import CardGoogle from '~/components/Home/CardGoogle'
import CardSingStapes from '~/components/Home/CardSignStapes'

// images
import logoGreen from '~/assets/imgs/logo-gofind-green.svg'
import googleDeveloper from '~/assets/imgs/icon-google-developers.png'
import logoWhite from '~/assets/imgs/logo-gofind-white.svg'
import iconBanner from '~/assets/imgs/icon-banner.svg'
import storeGoogle from '~/assets/imgs/bg-store-google.png'
import myStore from '~/assets/imgs/my-store.png'
import stars from '~/assets/imgs/Estrelas.png'
import costomer from '~/assets/imgs/Consumidor.png'
import path from '~/assets/imgs/Caminho.png'
import stepOne from '~/assets/imgs/1-passo.png'
import stepTwo from '~/assets/imgs/2-passo.png'
import stepThree from '~/assets/imgs/3-passo.png'
import favicon from '~/assets/imgs/favicon.png'
import sprite from '~/assets/imgs/sprite.svg'

const useStyle = makeStyles((theme) => ({
  body: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  section: {
    display: 'block',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
  },
  container: {
    maxWidth: 1210,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0 1.5rem',
    position: 'relative',
  },
  contentCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 150,
  },
  socialGrid: {},
}))

function H2({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      component="h2"
      sx={{
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '30px',
        lineHeight: '35px',
        letterSpacing: '.02em',
        color: '#93C400',
        textAlign: 'center',
        margin: '0 0 3rem',
      }}
    >
      {children}
    </Typography>
  )
}

const MyStoreImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    position: 'relative',
    right: 0,
    top: '-105px',
  },
}))

const MyStoreTextBody = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '21px',
  letterSpacing: '.02em',
}))

const Spotify = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <use href={`${sprite}#icon_spotify`} />
  </SvgIcon>
)

export const HomePage: React.FC = () => {
  const classes = useStyle()

  return (
    <Box className={classes.body}>
      <Helmet>
        <title>Gofind</title>
        <meta
          name="description"
          content="Landing Page Gofind exibe as vantagens e passo a passo para "
        />

        {/* Favicon IE 9 */}
        <link rel="icon" type="image/x-icon" href={favicon} />
        {/* Favicon Outros Navegadores */}
        <link rel="shortcut icon" type="image/png" href={favicon} />
        {/* Favicon iPhone */}
        <link rel="apple-touch-icon" href={favicon} />

        {/* Chrome, Firefox OS and Opera */}
        <meta name="theme-color" content="#0E3940" />

        {/* Safari */}
        <meta name="apple-mobile-web-app-status-bar-style" content="#0E3940" />

        {/* Google Tag Manager - GOFIND */}
        <script>
          {`
            (function(w, d, s, l, i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l!= 'dataLayer' ? '&l='+l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-NDV6BFS');
          `}
        </script>
        {/* End · Google Tag Manager - GOFIND */}
      </Helmet>
      <header className={classes.section}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <img src={logoGreen} style={{ width: 279, height: 52 }} alt="logo"></img>
            <img
              src={iconBanner}
              style={{ position: 'absolute', width: 179, height: 38, right: 0, bottom: 0 }}
              alt="icone do banner"
            ></img>
          </Box>
        </Box>
      </header>

      <main>
        {/* session store google */}
        <section
          className={classes.section}
          style={{ backgroundColor: '#0E3940', padding: '3rem 0' }}
        >
          <Box className={classes.container}>
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <Box
                width="70%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Typography
                  component="h1"
                  sx={{
                    fontFamily: 'Kumbh Sans',
                    fontWeight: 700,
                    fontSize: 30,
                    lineHeight: '35px',
                    letterSpacing: '.02em',
                    margin: '0 0 30px',
                    color: '#93c400',
                  }}
                >
                  Seu Comércio Local no Google:{' '}
                  <span style={{ color: '#fff', display: 'block' }}>
                    Cadastre sua loja e atraia mais cosumidores
                  </span>
                </Typography>
                <Button
                  sx={{
                    fontFamily: 'Kumbh Sans',
                    fontSize: '18px',
                    lineHeight: 'normal',
                    letterSpacing: '.02em',
                    padding: '15px 20px',
                    borderRadius: '25px',
                    backgroundColor: '#6511cd',
                    color: '#fff',
                    '&:hover': { backgroundColor: '#93C400' },
                  }}
                  variant="contained"
                  href="https://varejo.gofind.com.br/login"
                >
                  Começar agora
                </Button>
              </Box>
              <Box width="30%" className={classes.contentCenter}>
                <img
                  src={storeGoogle}
                  style={{ maxWidth: '100%', height: 'auto' }}
                  alt="Seu Comércio Local no Google"
                />
              </Box>
            </Box>
          </Box>
        </section>

        <section className={classes.section} style={{ padding: '5rem 0 3rem' }}>
          <Box className={classes.container}>
            <Grid container>
              <Grid item xs={12}>
                <H2>Porque cadastrar a minha loja?</H2>
              </Grid>
              <Grid
                xs={12}
                md={8}
                container
                item
                spacing={5}
                justifyContent="center"
                alignItems="flex-start"
                direction="row"
              >
                <Grid item xs={12} sm={6}>
                  <MyStoreTextBody>
                    Negócios locais são o combustível para tornar a economia forte. Por isso,
                    promover esse tipo de comércio gera <strong>ganhos para toda a cadeia</strong>
                    (fabricantes, lojistas, distribuidores e consumidores). Esse movimento que
                    conecta os pequenos e médios negócios das pessoas nas aproximidades, torna o
                    processo de compra e decisão <strong>mais justo e acessível para todos</strong>
                  </MyStoreTextBody>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyStoreTextBody>
                    Se conectar com o consumidor moderno é, também, se adaptar ao estilo de vida
                    desse público que está em plataformas digitais, mas que também{' '}
                    <strong>valoriza a economia local,</strong> prefere comprar do pequeno e busca
                    comodidade e facilidade para comprar.
                  </MyStoreTextBody>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                justifyContent={{ xs: 'center', md: 'flex-end' }}
              >
                <MyStoreImg src={myStore} alt="Negócios locais" />
              </Grid>
            </Grid>
          </Box>
        </section>

        <section className={classes.section}>
          <Box className={classes.container}>
            <Grid container xs={12} justifyContent="center">
              <H2>Benefícios do Comércio Local no Google:</H2>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={4}>
                <CardGoogle image={stars} text="Diferenciar-se da concorrência" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CardGoogle image={costomer} text="Mais conexão com o consumidor local" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CardGoogle
                  image={path}
                  text="Mais ligações, visitas e rotas traçadas para sua loja"
                />
              </Grid>
            </Grid>
          </Box>
        </section>

        <section className={classes.section} style={{ padding: '5rem 0 3rem' }}>
          <Box justifyContent="center" alignItems="center">
            <Typography
              align="center"
              sx={{
                fontFamily: 'Kumbh Sans',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '30px',
                lineHeight: '35px',
                letterSpacing: '.02em',
                color: '#6511CD',
                margin: '0 0 3rem',
              }}
            >
              Veja como é fácil se cadastrar:
            </Typography>
          </Box>
          <Grid container className={classes.container}>
            <Grid
              container
              item
              gap={3}
              justifyContent="center"
              alignItems="center"
              flexWrap={{ xs: 'wrap', md: 'nowrap' }}
            >
              <Grid item xs={12} md={4} justifyContent="center">
                <CardSingStapes
                  title="1º"
                  description="Cadastre sua loja através da Gofind. É rápido, fácil e sem custos."
                  image={stepOne}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CardSingStapes
                  title="2º"
                  description="Nós conectamos sua loja no Google Meu Negócio."
                  image={stepTwo}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CardSingStapes
                  title="3º"
                  description="Aumente sua presença online e receba mais consumidores."
                  image={stepThree}
                />
              </Grid>
            </Grid>
          </Grid>
        </section>

        <section className={classes.section} style={{ background: '#f5f5f5' }}>
          <Box padding="30px 0" display="flex" flexDirection="column" justifyContent="center">
            <Typography
              sx={{
                fontFamily: 'Raleway',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '30px',
                lineHeight: '35px',
                letterSpacing: '.02em',
                color: '#0E3940',
                textAlign: 'center',
                margin: '0 0 2rem',
                textTransform: 'uppercase',
              }}
            >
              PRIMEIRO ACESSO
            </Typography>

            <Box sx={{ margin: '30px auto 0' }}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: '25px',
                  background: '#0E3940',
                  color: '#93C400',
                  fontSize: '18px',
                  '&:hover': {
                    background: '#6511CD',
                    color: '#fff',
                  },
                }}
                href="https://varejo.gofind.com.br/login"
              >
                COMEÇAR AGORA
              </Button>
            </Box>
          </Box>
        </section>

        <footer className={classes.section}>
          <Box
            sx={{ padding: '30px 50px', minHeight: '200px', backgroundColor: '#0E3940' }}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent={{ xs: 'center', md: 'space-between' }}
            rowGap={{ xs: 7, md: 0 }}
            alignItems="center"
          >
            <img src={googleDeveloper} alt="Google Developer" style={{ maxWidth: '279px' }} />
            <img src={logoWhite} alt="Logo gofind footer" style={{ maxWidth: '279px' }} />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <Typography
                sx={{ fontFamily: 'Kumbh Sans', fontSize: '22px', color: '#fff', fontWeight: 700 }}
              >
                Nosas Redes
              </Typography>
              <Box
                className={classes.socialGrid}
                display="flex"
                flexDirection="column"
                gap={2}
                // rowGap={2}
              >
                <Box display="flex">
                  <IconButton
                    href="https://www.facebook.com/gofind.online/"
                    target="_blank"
                    title="Facebook"
                  >
                    <Facebook sx={{ fontSize: '36px', color: '#fff' }} />
                  </IconButton>
                  <IconButton
                    href="https://www.instagram.com/gofind.online/"
                    target="_blank"
                    title="Instagram"
                  >
                    <Instagram sx={{ fontSize: '36px', color: '#fff' }} />
                  </IconButton>
                  <IconButton
                    href="https://www.linkedin.com/company/gofindonline/"
                    target="_blank"
                    title="Linkedin"
                  >
                    <LinkedIn sx={{ fontSize: '36px', color: '#fff' }} />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    href="https://twitter.com/gofindonline"
                    target="_blank"
                    title="Twitter"
                  >
                    <Twitter sx={{ fontSize: '36px', color: '#fff' }} />
                  </IconButton>
                  <IconButton
                    href="https://open.spotify.com/show/6CQFrtaOkf4sxYzgAdul4y?si=iqqOGhWhSPaqjrqZ7ySFTA&nd=1"
                    target="_blank"
                    title="Spotify"
                  >
                    <Spotify sx={{ fontSize: '36px', color: '#fff' }} />
                  </IconButton>
                  <IconButton
                    href="https://www.youtube.com/channel/UClcD4qkPyFc0Sa2uTMp4oxg"
                    target="_blank"
                    title="Youtube"
                  >
                    <YouTube sx={{ fontSize: '36px', color: '#fff' }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </footer>
      </main>
    </Box>
  )
}
