import { useState } from 'react'
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded'

import { PeriodOutput } from '~/utils/google-parse-period'

type DialogTimeProps = {
  periods: PeriodOutput[] | null
  iconColor?: string
}

export function DialogTime({ periods, iconColor = 'inherit' }: DialogTimeProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleOpen = () => setIsOpen(true)

  const handleClose = () => setIsOpen(false)

  return (
    <>
      <Button
        onClick={handleOpen}
        startIcon={<AccessTimeFilledRoundedIcon style={{ fontSize: 32, color: iconColor }} />}
        style={{ height: 52 }}
      >
        <Typography
          variant="body1"
          color="secondary"
          style={{
            textTransform: 'initial',
            textDecoration: 'underline',
          }}
        >
          Ver mais horários
        </Typography>
      </Button>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
        <DialogTitle id="customized-dialog-title">
          <Typography gutterBottom color="secondary" style={{ fontWeight: 500 }}>
            Ver mais horários
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {periods?.map((period: PeriodOutput) => (
            <Grid
              key={period.dayOfWeek}
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ gap: 32, marginTop: 16 }}
            >
              <Typography gutterBottom color="secondary">
                {period.dayOfWeek}
              </Typography>
              <Typography gutterBottom color="secondary">
                {period.open === period.close ? period.close : `${period.open} - ${period.close}`}
              </Typography>
            </Grid>
          ))}
        </DialogContent>
      </Dialog>
    </>
  )
}
