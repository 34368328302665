import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { HomePage } from './pages/Home'
import { SelectCnpj } from './pages/SelectCnpj'
import { VehiclePage } from './pages/Vehicle'
import { PetsPage } from './pages/Pets'
import { QuartzolitPage } from './pages/Quartzolit'
import { SooperPage } from './pages/Sooper'
import { NoMatch } from './pages/NoMatch'

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<HomePage />} />
        <Route path="/busca" element={<SelectCnpj />} />

        <Route path="/:cnpj" element={<VehiclePage />} />

        <Route path="/carros/:cnpj" element={<VehiclePage vehicle="car" />} />
        <Route path="/motos/:cnpj" element={<VehiclePage vehicle="motorcycle" />} />

        <Route path="/pets/:cnpj" element={<PetsPage />} />
        <Route path="/quartzolit/:cnpj" element={<QuartzolitPage />} />
        <Route path="/sooper/:cnpj" element={<SooperPage />} />

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  )
}
