import { useState } from 'react'

import { PaperProps, Typography, Grid, Container, Button, Paper, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'

import ImageNotSupported from '~/assets/imageNotSupported.svg'

import { TextEllipsis } from '~/components/TextEllipsis'

import { useViewport } from '~/hooks/useViewport'

type Media = {
  name: string
  mediaFormat: string
  googleUrl: string
}

type Product = {
  summary: string
  searchUrl: string
  media: Array<Media>
}

type ProductsProps = PaperProps & {
  title: string
  direction?: 'column' | 'row'
  typeButton?: 'buyOnline' | 'knowMore'
  colorButton?: 'primary' | 'secondary'
  products: Array<Product> | undefined
}

const useStyles = makeStyles({
  listColumn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(250px, 1fr))',
    gap: 24,
    maxHeight: 1200,
    overflow: 'auto',
    padding: 16,
    '&::-webkit-scrollbar': { width: 6 },
    '&::-webkit-scrollbar-track': { backgroundColor: '#F1F1F1' },
    '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, .6)' },
  },
  listRow: {
    display: 'grid',
    gridAutoColumns: '320px',
    gridAutoFlow: 'column',
    gridColumnGap: 16,
    overflowX: 'auto',
    scrollSnapType: 'x proximity',
    padding: '16px 0',
    maxWidth: 985,
    '&::-webkit-scrollbar': { height: 6 },
    '&::-webkit-scrollbar-track': { backgroundColor: '#F1F1F1' },
    '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, .6)' },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    scrollSnapAlign: 'center',
  },
  card: {
    padding: 16,
    width: 300,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    gap: 8,
  },
})

export function Products({
  title,
  products,
  typeButton = 'knowMore',
  colorButton = 'secondary',
  direction = 'column',
  ...rest
}: ProductsProps) {
  const classes = useStyles()
  const { isMobile } = useViewport()

  const [moreProducts, setMoreProducts] = useState<number>(6)

  const hasShowProducts = 6 < Number(products?.length)

  const handleResetProducts = () => {
    setMoreProducts(6)
  }

  const handleMoreProducts = () => {
    setMoreProducts((prevProducts) => prevProducts + 3)
  }

  return (
    <Container maxWidth="xl" id="products" component="section" style={{ padding: '32px 0' }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ gap: 32, padding: isMobile ? '0 24px' : 0 }}
      >
        <Typography variant="h2" style={{ fontWeight: 'bold', fontSize: 24 }}>
          {title}
        </Typography>
        <Grid
          className={direction === 'column' ? classes.listColumn : classes.listRow}
          {...(isMobile && direction === 'row' && { sx: { maxWidth: '100%' } })}
        >
          {products
            ?.filter((product) => product.summary)
            ?.map((product, index) => (
              <Paper key={index} className={classes.paper} {...rest}>
                <Grid className={classes.card} style={{ flexDirection: 'column' }}>
                  <img
                    src={product?.media?.[0]?.googleUrl || ''}
                    alt={`product-${index}`}
                    loading="lazy"
                    width={265}
                    height={265}
                    style={{ objectFit: 'cover' }}
                    referrerPolicy="no-referrer"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.src = ImageNotSupported
                      currentTarget.style.objectFit = 'contain'
                    }}
                  />

                  <TextEllipsis
                    lineClamp={1}
                    variant="h3"
                    style={{ fontSize: 16, fontWeight: 700 }}
                  >
                    {product.summary.split('-')[0]}
                  </TextEllipsis>

                  <TextEllipsis variant="body1" style={{ fontSize: 14 }}>
                    {product.summary}
                  </TextEllipsis>
                </Grid>

                {typeButton === 'knowMore' ? (
                  <>
                    <Divider style={{ width: '100%' }} />

                    <Button
                      disableElevation
                      component="a"
                      href={product.searchUrl}
                      target="_blank"
                      variant={colorButton === 'primary' ? 'contained' : 'text'}
                      color={colorButton}
                      style={{
                        textDecoration: colorButton === 'primary' ? 'none' : 'underline',
                        fontWeight: 700,
                        textTransform: 'none',
                        margin: '8px 0',
                      }}
                    >
                      Saiba mais
                    </Button>
                  </>
                ) : (
                  <Button
                    disableElevation
                    component="a"
                    href={product.searchUrl}
                    target="_blank"
                    variant="contained"
                    color="primary"
                    style={{
                      fontWeight: 700,
                      textTransform: 'uppercase',
                      margin: '8px 0',
                    }}
                  >
                    Compre aqui
                  </Button>
                )}
              </Paper>
            ))
            ?.slice(0, moreProducts)}
        </Grid>

        {hasShowProducts && direction === 'column' && (
          <>
            {moreProducts >= Number(products?.length) ? (
              <Button
                variant="outlined"
                style={{
                  width: 200,
                  textTransform: 'none',
                  color: '#000',
                }}
                onClick={handleResetProducts}
              >
                Ver menos produtos
              </Button>
            ) : (
              <Button
                variant="outlined"
                style={{
                  width: 200,
                  textTransform: 'none',
                  color: '#000',
                }}
                onClick={handleMoreProducts}
              >
                Ver mais produtos
              </Button>
            )}
          </>
        )}
      </Grid>
    </Container>
  )
}
